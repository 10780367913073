import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import List from "./List";
import SlickSlider from "./SlickSlider";
import { fetchFeatured } from "../redux/actions/property";

const Featured = () =>
{
    const dispatch = useDispatch();
    const property = useSelector( ( state ) => state.property.featured );

    useEffect( () =>
    {
        !property?.length && dispatch(fetchFeatured());
    }, [] );




    return (
        <div className="3xl:pb-8 bg-gray-100 ">
            <div className="lg:container mx-10 lg:mx-auto pt-8 pb-3 flex justify-start 3xl:max-w-[1500px]">
                <h6 className="text-navy font-Lexend sm:mx-10">
                    <span className="font-bold  3xl:pl-0 4xs:text-2xl 3xl:text-3xl">Featured</span> <span className="4xs:text-2xl 3xl:text-3xl">Listings</span>
                </h6>
            </div>
            <div className=" container mx-auto">
                <SlickSlider className="w-full">
                    { property.length > 0 &&
                        property
                            .filter( ( p ) => p.status === "Active" && p.featured === true )
                            .map(
                                ( prop, index ) =>
                                (
                                    <List

                                        key={ prop.id }
                                        property={ prop }
                                    />
                                )
                            ) }
                </SlickSlider>

            </div>
        </div>
    );
};

export default Featured;
